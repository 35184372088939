import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CompanyService } from 'src/app/services/company.service';
import { GlobalService } from 'src/app/services/global.service';
import { AwsContractsService } from 'src/app/services/main/home/contracts/aws-contracts.service';
import { AwsSupplierManagementService } from 'src/app/services/main/home/supplier-management/aws-supplier-management.service';
@Component({
  selector: 'app-log-activity-review-global',
  templateUrl: './log-activity-review.component.html',
  styleUrl: './log-activity-review.component.scss'
})
export class LogActivityReviewGobalComponent implements OnInit{
  @Input() idSupplier = "";
  loadLog = false;
  activities = [];
  lastKey = null;
  constructor(
    private globalService: GlobalService,
    private companyService: CompanyService,
    private aws: AwsSupplierManagementService,
  ){

  }

  ngOnInit(): void {
    this.getLog();
  }

  async getLog(){
    this.loadLog = true;
    try {
      let body = { 
        idCompany:this.companyService.idCompany,
        idSupplier: this.idSupplier
      }
      let result = await firstValueFrom(this.aws.supplierManagementLog(body));
      if(!result['status']){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST', });
      }else{
        if(this.lastKey){
          this.activities = this.activities.concat(result['data']['Items']);
        }else{
          this.activities = result['data']['Items'];
        }
        this.lastKey = result['data']['lastKey'];
      }
    } catch (error) {
      if (error.status === 401) {
        this.globalService.sendRequest({severity: 'error', summary: 'Error', detail: error.error['message'], type: 'TOAST'});
      }else if (error.status === 400){
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST' });
      }else{
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'Error de sistema.', type: 'TOAST' });
      }
    }
    this.loadLog = false;
  }

  async loadData() {
    if(!this.loadLog){
      let element = document.getElementById("scroll");
      if (element.offsetHeight + element.scrollTop + 5 >= element.scrollHeight) {
        if(this.lastKey){
          this.getLog();
        }
      }
    }
  }
}
