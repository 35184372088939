<div class="grid scrollStyle m-0 layout-container" id="scroll" (scroll)="loadData()" style="height:calc(var(--doc-height) - 165px) ; overflow-x: hidden; overflow-y: auto;">
    <div class="col-12 hidden md:block">
        <div class="grid m-0">
            <div class="col-4 p-0">
                <div class="grid m-0">
                    <div class="col-12 p-0">
                        <span class="font-bold" translate>date_and_time</span>
                    </div>
                </div>
            </div>
            <div class="col-8 p-0">
                <div class="grid m-0">
                    <div class="col-12 p-0">
                        <span class="font-bold" translate>activities</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let item of activities">
        <div class="col-12 mb-2 class-border {{item.action}}-BORDER">
            <div class="grid m-0">
                <div class="col-12 md:col-4 p-0">
                    <div class="grid">
                        <div class="col-12" style="padding-top: 13px;">
                            <span class="font-bold"><i class="fas fa-clock mr-1"></i> {{(item.createdDate * 1000) | date: 'dd/MM/yyyy, h:mm:ss a'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-8 p-0">
                    <div class="grid list-activity">
                        <div class="col-12">
                            <span class="font-bold text-base flex align-items-center" style="color: var(--bold-text);">
                                <span class="bg-circle-log {{item.action}}" ></span>
                                <img class="mr-2" style="border-radius: 50%; width: 1.5rem;
                                        height: 1.5rem;" [src]="item.avatar ? item.avatar : 'assets/images/user.svg'" alt="" srcset="" [pTooltip]="item.email" tooltipPosition="left">
                                {{item.name}} {{item.lastName}}
                            </span>
                        </div>
                        <div class="col-12 pt-0 mt-0">
                            <div class="grid m-0" style="margin-left: 30px!important;">
                                <div class="col-12">
                                    <span translate>{{item.action}}</span>
                                </div>

                                @if (item.action === "APPROVED_THE_REQUEST" || item.action === 'OBSERVED_THE_REQUEST'){
                                    @if (item['content']&& item['content']['reason'] && item['content']['reason'].length > 0) {
                                        <div class="col-12"><span><span class="font-bold" translate>motive</span>: </span></div>
                                        <div class="col-12">
                                            <div [innerHTML]="item['content']['reason']"></div>
                                        </div>
                                    }
                                    @if (item['content'] && item['content']['files'] && item['content']['files'].length > 0){
                                        <div class="col-12"><span><span class="font-bold" translate>document</span>: </span></div>
                                        <div class="col-12"><app-list-file-template [files]="item.content.files" type="DOWNLOAD"></app-list-file-template></div>
                                    }
                                    @if (item['content'] && item['content']['formatsObserved'] && item['content']['formatsObserved'].length > 0){
                                        <div class="col-12"><span><span class="font-bold" translate>Formatos</span>: </span></div>
                                        <div class="col-12">
                                            <p-table [value]="item['content']['formatsObserved']" [scrollable]="true">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="min-width: 200px;">
                                                            Nombre formato
                                                        </th>
                                                        <th style="min-width: 130px; width: 130px;" translate>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-item  let-index="rowIndex">
                                                    <tr>
                                                        <td>
                                                            <span class="font-bold">{{item.nameRequest}}</span>
                                                        </td>
                                                        <td>
                                                            <button pButton (click)="op.toggle($event); tempData = item.observedReason" type="button" class="w-button p-button-rounded p-button-secondary" label="MOTIVO"></button> 
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <p-overlayPanel #op>
                                            <div class="grid m-0">
                                                <div class="col-12 html-content" style="white-space: pre-wrap;">
                                                    {{tempData}}
                                                </div>
                                            </div>
                                        </p-overlayPanel>
                                    }
                                }
                                @if (item.action === "ADDED_CREDIT_REPORT_SUPPLIER" || item.action === 'DELETED_CREDIT_REPORT_SUPPLIER'){
                                    @if (item['content']&& item['content']['financialEntity'] && item['content']['financialEntity'].length > 0) {
                                        <div class="col-12"><span><span class="font-bold" translate>issuing_report</span>: </span></div>
                                        <div class="col-12">
                                            <div>
                                                {{item['content']['financialEntity']}}
                                            </div>
                                        </div>
                                    }
                                    @if (item['content']&& item['content']['financialReferenceCreditRating']) {
                                        <div class="col-12"><span><span class="font-bold" translate>credit_level_obtained</span>: </span></div>
                                        <div class="col-12">
                                            <div>
                                                {{item['content']['financialReferenceCreditRating'] | number:'1.2-2'}}
                                            </div>
                                        </div>
                                    }
                                    @if (item['content']&& item['content']['financialReferenceReportDate']) {
                                        <div class="col-12"><span><span class="font-bold" translate>report_date</span>: </span></div>
                                        <div class="col-12">
                                            <div>
                                                {{item['content']['financialReferenceReportDate'] * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}
                                            </div>
                                        </div>
                                    }

                                    @if (item['content'] && item['content']['financialReferenceReportFile'] && item['content']['financialReferenceReportFile'].length > 0){
                                        <div class="col-12"><span><span class="font-bold" translate>credit_report</span>: </span></div>
                                        <div class="col-12"><app-list-file-template [files]="item['content']['financialReferenceReportFile']" type="DOWNLOAD"></app-list-file-template></div>
                                    }
                                }
                                @if (item.action === 'UPDATED_THE_APPROVAL_FLOW'){
                                    <div class="col-12"><span><span class="font-bold" translate>type</span>: <span translate>{{item['content']['typeApproval']}}</span> </span></div>
                                    <div class="col-12"><span><span class="font-bold" translate>approvers</span>: </span></div>
                                    <div class="col-12">
                                        <p-table [value]="item['content']['approvers']" [scrollable]="true">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th style="min-width: 50px; width: 50px;">
            
                                                    </th>
                                                    <th style="min-width: 150px;" translate>
                                                        approver
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-item  let-index="rowIndex">
                                                <tr>
                                                    <td>
                                                        <img class="image-avatar-approver" pTooltip="{{item.name}} {{item.lastName}}" [style.border-color]="item.symbol"  tooltipPosition="left" [src]="item.avatar ? item.avatar : 'assets/images/user.svg'" style="border-color: back;" alt="">
                                                    </td>
                                                    <td>
                                                        <span class="font-bold">{{item.name}} {{item.lastName}}</span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                }   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="col-12 mb-2" *ngIf="loadLog">
        <div class="grid m-0">
            <div class="col-12 md:col-4 p-0">
                <div class="grid">
                    <div class="col-12" style="padding-top: 15px;">
                        <span class="font-bold flex">
                            <span><p-skeleton shape="circle" size="18px" styleClass="mr-2"></p-skeleton> </span>
                            <span><p-skeleton width="122px" height="18px" borderRadius="16px"></p-skeleton></span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-8 p-0">
                <div class="grid list-activity">
                    <div class="col-12">
                        <span class="font-bold text-base flex align-items-center" style="color: var(--bold-text);">
                            <p-skeleton shape="circle" size="18px" styleClass="mx-2"></p-skeleton>
                            <p-skeleton shape="circle" size="24px" styleClass="mr-2"></p-skeleton>
                            <p-skeleton width="10rem" height="18px"  borderRadius="16px"></p-skeleton>
                        </span>
                    </div>
                    <div class="col-12 pt-0 mt-0">
                        <div class="grid m-0" style="margin-left: 30px!important;">
                            <div class="col-12">
                                <span translate><p-skeleton width="10rem" borderRadius="16px"></p-skeleton></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
