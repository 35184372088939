import { Component, OnInit ,Output ,EventEmitter} from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import { UntypedFormBuilder, Validators,UntypedFormGroup,UntypedFormControl } from '@angular/forms';
import { SessionIn } from 'src/app/interfaces/session-in';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
@Component({
  selector: 'app-modal-complete-profile',
  templateUrl: './modal-complete-profile.component.html',
  styleUrls: ['./modal-complete-profile.component.scss'],
})
export class ModalCompleteProfileComponent implements OnInit {
  @Output() closeModalCompleteProfile = new EventEmitter<Boolean>();
  maxDate = new Date();
  modalCompletProfile = true;
  sessionIn: SessionIn;
  gender = [
    { label: 'male', value: 'M' },
    { label: 'female', value: 'F' },
  ];
  submitted: boolean;
  initialCompletedProfile: UntypedFormGroup;
  profilePhoto = null;
  codePhone = [];
  logo_ramdon = 12;
  completedProfileUser = false; 

  displayImagencrop = false;
  type_crop = 1;

  loadSubmit = false;
  loadDependency = false;
  desktop = true;
  constructor(
    private fb: UntypedFormBuilder,
    private globalService: GlobalService,
    public trans: TranslateService,
    private sessionService: SessionService,
    private awsShared: AwsSharedService
  ) {
    this.desktop = globalService.desktop;
    this.sessionIn = this.sessionService.sessionIn;
    this.initialCompletedProfile = this.fb.group({
      name: ['', Validators.compose([this.validateSpacesTrim])],
      lastName: ['', Validators.compose([this.validateSpacesTrim])],
      email: [''],
      dateBirth: [null, Validators.compose([Validators.required])],
      prefixCellphone: ['', Validators.compose([this.validateSpacesTrim])],
      cellphone: ['', Validators.compose([this.validateSpacesTrim])],
      prefixCodePhone: '',
      phone: '',
      specialOffers: false,
      contestEvents: false,
      featuresReleases: false,
      tipsInspiration: false,
      sex: ['', Validators.compose([Validators.required])],
    });

  }

  ngOnInit(): void {
    this.getPhoneCountry();
  }

  async onSubmit(value) {
    if (this.initialCompletedProfile.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      var dateBirth = value.dateBirth.getTime() / 1000;
      let body = {
        name: value.name.trim().toUpperCase(),
        familyName: value.lastName.trim().toUpperCase(),
        cellPhone: value.cellphone,
        cellPhonePrefix: value.prefixCellphone,
        phone: value.phone,
        phonePrefix: value.prefixCodePhone,
        birthday: dateBirth,
        specialOffers: value.specialOffers,
        contestsAndEvents: value.contestEvents,
        newFeaturesAndReleases: value.featuresReleases,
        tipsAndInspiration: value.tipsInspiration,
        sex: value.sex,
      };
      this.loadSubmit = true;
      let result = await firstValueFrom( this.awsShared.updateUserAttributes(body));
      this.loadSubmit = false;
      if (result['status'] === false) {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: result['message'],
          type: 'TOAST',
        });
      } else if (result['status'] === true) {
        this.globalService.sendRequest({
          severity: 'success',
          summary: 'Éxito',
          detail: result['message'],
          type: 'TOAST',
        });
        let objSession: SessionIn = {
          avatar: this.profilePhoto,
          cellPhone: value.cellphone,
          cellPhonePrefix: value.prefixCellphone,
          email: this.sessionIn.email,
          lastName: value.lastName.trim().toUpperCase(),
          name: value.name.trim().toUpperCase(),
          phone: value.phone,
          phonePrefix: value.prefixCodePhone,
        };
        this.sessionService.setSessionValues(
          objSession,
          this.sessionService.token,
          this.sessionService.revokeToken
        );
        this.completedProfileUser = true;
        //llamar a servicio para actualizar el card
        let updateValue = { "completeProfile":true }
        let update = await firstValueFrom( this.awsShared.getUpdatefollowSetps(updateValue));
        this.globalService.sendRequest({ type: 'STATUSCOMPLETEDPROFILE'});
        this.globalService.sendRequest({ type: 'UPDATECARD' });
        this.closeModal();
      } else {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: 'ERROR_THE_REQUEST',
          type: 'TOAST',
        });
      }
    }
  }

  async getPhoneCountry() {
    this.loadDependency = true;
    let result = await firstValueFrom(this.awsShared.getCountries());
    this.loadDependency = false;
    if (result['status'] === false) {
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: result['message'],
        type: 'TOAST',
      });
    } else if (result['status'] === true) {
      let listCountries = result['data'];
      this.codePhone = listCountries.filter((c) => c['statusPhone']).map((c) => {
          return {
            label: c['name' + `${this.globalService.lang.charAt(0).toUpperCase()}` + `${this.globalService.lang.slice(1)}`],
            value: `+${c['codePhone']}`
          };
        });
        this.codePhone.sort((a,b) => a.label > b.label ? 1 : -1)
    } else {
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: 'ERROR_THE_REQUEST',
        type: 'TOAST',
      });
    }
    this.openModal();
  }

  async openModal() {
    this.submitted = false;
    this.initialCompletedProfile.patchValue({
      name: this.sessionIn.name,
      lastName: this.sessionIn.lastName,
      email: this.sessionIn.email,
      dateBirth: null,
      prefixCellphone: '',
      cellphone: '',
      prefixCodePhone: '',
      phone: '',
      specialOffers: false,
      contestEvents: false,
      featuresReleases: false,
      tipsInspiration: false,
      sex: '',
    });
    this.initialCompletedProfile.controls['email'].disable();
    this.profilePhoto = this.sessionIn.avatar;
  }

  get f() {
    return this.initialCompletedProfile.controls;
  }

  validateSpacesTrim(control: UntypedFormControl) {
    let code = control.value;
    if (code && code.trim().length > 0) {
      return null;
    } else {
      return { invalidSpace: true };
    }
  }

  closeModal() {
    this.closeModalCompleteProfile.emit();
    this.submitted = false;
    this.initialCompletedProfile.reset();
  }

  async saveFile(data) {
    this.globalService.sendRequest({ type: 'LOAD', status: true });
    const blob = this.dataURLtoFile(data.file_base64, 'avatar.png');
    let preSigned = await firstValueFrom(this.awsShared.uploadFileS3All({ "type": 'USER', "typeValue": "USER", "path": "user,avatar", "name": "avatar.png" }));
    if(preSigned['status']){
      let result = await lastValueFrom(this.awsShared.uploadFileToS3({ url: preSigned['data']['url'], file: blob }));
      if(result && result['status'] === 200){

        this.profilePhoto = result['url'].split("?")[0];
        let resultCompanyName = await firstValueFrom(this.awsShared.updateAvatar({ url: this.profilePhoto }));
        this.logo_ramdon = Math.random();
        let objSession: SessionIn = {
          avatar: this.profilePhoto,
          cellPhone: this.sessionIn.cellPhone,
          cellPhonePrefix: this.sessionIn.cellPhonePrefix,
          email: this.sessionIn.email,
          lastName: this.sessionIn.lastName,
          name: this.sessionIn.name,
          phone: this.sessionIn.phone,
          phonePrefix: this.sessionIn.phonePrefix,
        };
        this.sessionService.setSessionValues(objSession, this.sessionService.token, this.sessionService.revokeToken);
        //llamar a servicio para actualizar el card
        this.globalService.sendRequest({ type: 'UPDATECARD' });
      }else{
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
      }
    }else{
      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
    }
    this.globalService.sendRequest({ type: 'LOAD', status: false });
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
    return new File([u8arr], filename, {type:mime});
  }
} 
