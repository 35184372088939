import { Component, OnInit ,Input, EventEmitter, Output, ViewChild ,ElementRef, OnDestroy} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-comment-response',
  templateUrl: './comment-response.component.html',
  styleUrls: ['./comment-response.component.scss']
})
export class CommentResponseComponent implements OnInit, OnDestroy {
  @Input() item = null;
  @Input() comment = null;
  @Output() deleteComment = new EventEmitter<Object>();
  @Output() new = new EventEmitter<Object>();
  @Output() newList = new EventEmitter<Object>();
  selectedComment = null;
  emojii18n = null;
  statusEdit = false;
  items = [{
    label: 'Opciones',
    items: [{
        label: 'Actualizar',
        icon: 'pi pi-refresh',
        command: () => {
          this.openEdit();
        }
    },
    {
        label: 'Borrar',
        icon: 'pi pi-times',
        command: () => {
            this.deletedComment();
        }
    }
  ]}
  ];

  itemsCancel = [{
    label: 'Opciones',
    items: [
    {
        label: 'Cancelar',
        icon: 'pi pi-times',
        command: () => {
            this.cancelEditComment();
        }
    }
  ]}
  ];
  temporalCommentAffterCancel = '';
  loadingCommentUpdate = false;
  textPost: string = '';
  themeSelect = '';
  @ViewChild("input") input: ElementRef;
  subscriber: Subscription;
  constructor(private aws: AwsHubHomeService, private globalService: GlobalService,public trans: TranslateService) {
    this.themeSelect = this.globalService.theme
    this.subscriber = this.globalService.request.subscribe((res) => {
      if(res){
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateServiceEmoji();
            break;
          case 'UPDATETHEME':
            this.themeSelect = this.globalService.theme
            break;
            default:
            break;
        }
      }
    });
   }

  ngOnInit(): void {
    this.translateServiceEmoji();
  }
  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
  }
  async translateServiceEmoji(){
    let emojiLang = await new Promise((resolve, reject) => {
      this.trans.get('emoji').subscribe((res) => {
        resolve(res);
      });
    });
    this.emojii18n = emojiLang;
  }
  showOptions(op, event, comment, type){
    op.show(event);
    this.selectedComment = comment;
    this.temporalCommentAffterCancel = comment.comment;
  }

  showOptionsEditComment(opCancel, event, comment, type){
    opCancel.show(event);
  }


  async deletedComment(){
    let result = await firstValueFrom(this.aws.deleteComment({ id: this.selectedComment['id'] }));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.deleteComment.emit(this.selectedComment);
      if(this.selectedComment['type'] === "ANSWER"){
        this.comment['comment']['amountOfComment'] = this.comment['comment']['amountOfComment'] - 1;
        // this.listComment = this.listComment.filter(c => c['comment']['id'] != this.selectedComment['id']);
      }
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  openEdit(){
    this.statusEdit = true;
  }
  async editComment(){
    this.loadingCommentUpdate = true;
    let body = {
      id:this.selectedComment['id'],
      comment: this.item['comment']['comment'],
      createdDate : this.selectedComment['createdDate']
    }

    let result = await firstValueFrom(this.aws.updatePostComment(body));
       if(result['status'] === false){
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
      }else if(result['status'] === true){
        
        this.statusEdit = false;
      }else{
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
      }
      this.loadingCommentUpdate = false;
  }

  cancelEditComment(){
    this.item['comment']['comment'] = this.temporalCommentAffterCancel;
    this.statusEdit = false;
  }

  addEmoji(selected) {
    const emoji: string = (selected.emoji as any).native;
    const input = this.input.nativeElement;
    input.focus();

    if (document.execCommand){ 
      var event = new Event('input');
      document.execCommand('insertText', false, emoji);
      return; 
    }
       // insert emoji on carrot position
    const [start, end] = [input.selectionStart, input.selectionEnd]; 
    input.setRangeText(emoji, start, end, 'end');
  }
}
