<div class="grid">
    <div class="col-12">
        <div class="col-12 dropZone p-2 relative m-0 flex align-content-center justify-content-center flex-wrap" appDnd (fileDropped)="onFileDropped($event)">
            <input class="opacity-0 absolute top-0 left-0 w-full h-full z-2 cursor-pointer" type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
            <img src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/others/uploader.png" alt="" class="w-full">
            <p class="text-center m-0">{{ 'drop_files' | translate }} <br> {{ 'or' | translate }} <label for="fileDropRef"><b>{{ 'SEARCH' | translate }}</b></label></p>                
        </div>
    </div>
    <div class="col-12">
        <!-- <p-scrollPanel [style]="{width: '100%', height: '250px'}"> -->
            <ng-container *ngFor="let file of files">
                <div class="grid card-file">
                    <div class="col-2 text-center">
                        <i class="far {{file.icon}} icon-ext" [style.color]="file.color"></i>
                    </div>
                    <div class="col-8">
                        <div class="text-overflow-ellipsis w-full overflow-hidden white-space-nowrap">{{file.name}}</div>
                        <span>{{file.size}}</span>
                        <p-progressBar [value]="file.progress" styleClass="progress-file" [showValue]="false"></p-progressBar>
                    </div>
                    <div class="col-2 flex align-items-center pt-3 justify-content-center">
                        <i style="color: #69b094;" *ngIf="file.status === 'FINALIZED'" class="fas fa-check-circle mr-2"></i>
                        <button pButton pRipple type="button" icon="far fa-trash-alt" class="p-button-rounded p-button-danger p-button-outlined " (click)="deleteFile(file)"></button>
                    </div>
                </div>
            </ng-container>
        <!-- </p-scrollPanel> -->
    </div>
</div>
