import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AwsSupplierManagementService {

  constructor(
    private http: HttpClient, 
    private sessionService: SessionService
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks',
      'Auth': this.sessionService.token
    })
  };

  validateStateForm(body){
    return this.http.post(`${environment.baseUrlAPI}/user/validateStateForm`, body,  this.httpOptions);
  }

  listInviteCompanies(body){
    return this.http.post(`${environment.baseUrlAPI}/company/listInviteCompanies`, body , this.httpOptions);
  }

  //listar dependencias proveedores de una empresa premium
  listDependencySuppliers(){
    return this.http.post(`${environment.baseUrlAPI}/user/listCompanySecoundCategoriesCBN`, {},  this.httpOptions);
  }

  //listar proveedores de una empresa premium
  listSuppliers(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listCompanySupplierPagination`, body,  this.httpOptions);
  }

  listSuppliersLevels(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listCompanySupplierAll`, body,  this.httpOptions);
  }

  createNotificationRequest(body){
    return this.http.post(`${environment.baseUrlAPI}/user/createNotificationRequest`, body,  this.httpOptions);
  }

  listarNotificationRequest(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getNotificationRequestForCompany`, body,  this.httpOptions);
  }

  eliminarNotificationRequest(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/user/deleteNotificationRequest`, httpOptionsTemp);
  }

  listarNotificationSended(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getCompayNotificationsForCompany`, body,  this.httpOptions);
  }

  getFileXlsxProviders(body){
    return this.http.post(`${environment.baseUrlAPI}/user/exportCompanySupplierAll`, body,  this.httpOptions);
  }

  getItemsAccountBank(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getCompanyBankAccounts`, body,  this.httpOptions);
  }

  getProfile(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getDataCompany`, body,  this.httpOptions);
  }

  downloadInfom(body){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }; 
    return this.http.post(`${environment.informUrlAPI}`, body, httpOptions);
  }

  technicalReportDocx(body){
    return this.http.post(`${environment.baseUrlAPI}/company/technicalReportDocx`, body , this.httpOptions);
  }

  updateCodeSupplierCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/company/updateCodeSupplierCompany`, body , this.httpOptions);
  }

  inviteSupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/invite`, body , this.httpOptions);
  }

  evaluateCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/evaluateCompany`, body , this.httpOptions);
  }

  inviteToEvaluateCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/user/inviteToEvaluateCompany`, body , this.httpOptions);
  }

  dependencyEvaluationPanel(body){
    return this.http.post(`${environment.baseUrlAPI}/user/dependencyEvaluationPanel`, body , this.httpOptions);
  }

  download_file(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' })
  }

  removeCompanySupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/remove`, body , this.httpOptions);
  }

  getCompanySupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/user/listResumProvidersCompany`, body,  this.httpOptions);
  }

  updateClasificationCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/company/updateClasificationCompany`, body , this.httpOptions);
  }

  updateStatusByCompanyCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/company/updateStatusByCompany`, body , this.httpOptions);
  }

  listStatusCompanySupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/company/listStatusCompanySupplier`, body , this.httpOptions);
  }

  dependencyCompanySupplierPanel(body){
    return this.http.post(`${environment.baseUrlAPI}/company/dependencyCompanySupplierPanel`, body , this.httpOptions);
  }

  updateColumnsCompanySuppliersPanel(body){
    return this.http.post(`${environment.baseUrlAPI}/company/updateColumnsCompanySuppliersPanel`, body , this.httpOptions);
  }

  listPoliciesCompanySupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/company/listPoliciesCompanySupplier`, body , this.httpOptions);
  }

  documentApprovalRequest(body){
    return this.http.post(`${environment.baseUrlAPI}/company/documentApprovalRequest/create`, body , this.httpOptions);
  }

  documentApprovalRequestList(body){
    return this.http.post(`${environment.baseUrlAPI}/company/documentApprovalRequest/list`, body , this.httpOptions);
  }

  documentApprovalRequestDelete(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/company/documentApprovalRequest/delete`, httpOptionsTemp);
  }

  requiredFormatsSupplierList(body){
    return this.http.post(`${environment.baseUrlAPI}/company/requiredFormatsSupplier/list`, body , this.httpOptions);
  }

  documentApprovalRequestApproved(body){
    return this.http.post(`${environment.baseUrlAPI}/company/documentApprovalRequest/approved`, body , this.httpOptions);
  }

  documentApprovalRequestObserve(body){
    return this.http.post(`${environment.baseUrlAPI}/company/documentApprovalRequest/observe`, body , this.httpOptions);
  }

  requiredFormatsSupplierStatus(body){
    return this.http.post(`${environment.baseUrlAPI}/company/requiredFormatsSupplier/status`, body , this.httpOptions);
  }

  supplierManagementRequests(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/requests`, body , this.httpOptions);
  }

  supplierManagementRequestsGraphic(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/requests/graphic`, body , this.httpOptions);
  }
  
  sendSupplierSapConfipetrol(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/sendConfipetrol`, body , this.httpOptions);
  }

  supplierManagementRequestApprovers(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/request/approvers`, body , this.httpOptions);
  }

  supplierManagementRequestDetail(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/request/detail`, body , this.httpOptions);
  }

  supplierManagementRequestApprove(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/request/approve`, body , this.httpOptions);
  }

  supplierManagementRequestObserve(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/request/observe`, body , this.httpOptions);
  }

  supplierManagementLog(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/log`, body , this.httpOptions);
  }

  supplierManagementCreditReport(body){
    return this.http.put(`${environment.baseUrlAPI}/company/supplierManagement/creditReport`, body , this.httpOptions);
  }

  supplierManagementCreditReportPost(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/creditReport`, body , this.httpOptions);
  }

  supplierManagementCreditReportDelete(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/company/supplierManagement/creditReport`, httpOptionsTemp);
  }

  supplierManagementApproversDetail(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/approvers/detail`, body , this.httpOptions);
  }

  supplierManagementApproversUpdate(body){
    return this.http.post(`${environment.baseUrlAPI}/company/supplierManagement/approvers/update`, body , this.httpOptions);
  }

  updateTagsSupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/company/updateTagsSupplier`, body , this.httpOptions);
  }
}
