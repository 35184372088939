<div class="notifications ">
	<ng-container *ngFor="let notification of notifications">
		<div [id]="notification.id" class="notification shadow-6 animate__animated animate__bounceInRight">
			<ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
		</div>
	</ng-container>
</div>

<ng-template #notificationTpl let-notification="notification">
	<div class="header">
		<div class="flex align-items-center">
			<i class="fas fa-bell mr-2"></i> <div translate>NOTIFICATION</div>
		</div>
		 <i class="fas fa-times cursor-pointer" (click)="close(notification)"></i>
	</div>
	<div class="container-notify cursor-pointer" (click)="goUrl(notification)">
		<div class="image">
			@if(notification.image.length > 0){
				<img src="{{notification.image}}" alt="" srcset="">
			}@else{
				<p-avatar label="{{notification.title.charAt(0).toUpperCase()}}" [style]="{'width':'60px', 'height': '60px'}" shape="circle"></p-avatar>
			}
		</div>
		<div class="container-message">
			<div class="title font-bold">{{notification.title}}</div>
			<div class="message" [innerHTML]="notification.message"></div>
		</div>
	</div>
</ng-template>

