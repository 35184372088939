<p-sidebar [(visible)]="displayInfoSupplier" position="right" [baseZIndex]="10000" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" [blockScroll]="true" styleClass="sidebar-100vw">
    <div class="grid hidden md:flex">
        <ng-container *ngIf="loadComplete">
            <div class="col-12 text-center w-full h-full flex align-items-center justify-content-center"
                style="height: calc(100vh - 48px)!important;">
                <p-progressSpinner [style]="{width: '150px', height: '250px'}"></p-progressSpinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadComplete">
            <div class="col-4 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center"
                            style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                            <div class="col-12">
                                <h2 class="m-0" translate>SUMMARY</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
                            <ng-container *ngIf="profile">
                                <div class="grid m-0">
                                    <div class="col-12 text-center">
                                        <div class="container mx-2 shadow-5 mb-3" style="border-radius: 24px;">
                                            <div class="card-profile" style="padding-top: 20px;">
                                                <img *ngIf="profile.logo.length > 0" class="w-full px-3"
                                                    style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                                    [src]="profile.logo" alt="">
                                                <img *ngIf="profile.logo.length === 0" class="w-full px-3"
                                                    style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                                    src="assets/images/no_logo.jpeg" alt="">
                                                <div class="card-profile_user-stats relative">
                                                    <div class="grid m-0">
                                                        <div class="col-12" style="margin-top:10px">
                                                            <div class="stats-holder">
                                                                <div class="user-stats">
                                                                    <div class="user-infos">
                                                                        <span class="infos_name">{{profile.businessName}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="stats-holder flex align-items-center justify-content-center">
                                                                    <img class="mr-3" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{profile.country | lowercase}}.png" alt="" srcset="">
                                                                    <p-tag [value]="profile.tradeName" class="tag-info"></p-tag>
                                                                    <span class="inline-block ml-3" style="height: 24px;"
                                                                        *ngIf="profile.homologation === 'YES'">
                                                                        <app-animated-check>
                                                                        </app-animated-check>
                                                                    </span>
                                                                </div>
                                                                <div class="grid mt-2">
                                                                    <div class="col-12">
                                                                        <div class="user-stats flex align-items-center justify-content-between">
                                                                            <span>
                                                                                <p-tag styleClass="ml-6 mr-2"
                                                                                    severity="info" [value]="years"></p-tag>
                                                                            </span>
                                                                            <span>{{profile.codeTributary}}</span>
                                                                            <span>
                                                                                <button pButton pRipple type="button" icon="pi pi-paperclip" label="{{profile.files.length}}" 
                                                                                class="p-button-rounded p-button-secondary mr-6 p-button-outlined" (click)="files = profile.files; opFiles.toggle($event)"></button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <span>{{profile.direction}} <br>
                                                                                {{countryName}} - {{profile.state}} - {{profile.city}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong
                                                                                translate>Tipo de persona</strong>
                                                                            <span>
                                                                                <p class="m-0">
                                                                                    <span class="mr-2 inline-block ">
                                                                                        <span translate class="uppercase">{{profile.typePerson | lowercase}}</span>
                                                                                    </span>
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong
                                                                                translate>contact_</strong>
                                                                            <span>
                                                                                <p class="m-0">
                                                                                    <span class="mr-2 inline-block ">
                                                                                        <i
                                                                                            class="fas fa-mobile-alt mr-2"></i>
                                                                                        <span
                                                                                            class="">{{profile.prefixPhone}}
                                                                                            {{profile.phone}}</span>
                                                                                    </span>
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong translate>type_company</strong>
                                                                            <span>{{typeCompany}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong translate>commercial_section</strong>
                                                                            <span>{{commercialSectionList}}</span>
                                                                        </div>
                                                                    </div>
                                                                    @if(profile.comercialActivity.length > 0){
                                                                        <div class="col-12">
                                                                            <div class="user-stats">
                                                                                <strong translate>comercial_activity</strong>
                                                                                <span class="text-center">
                                                                                    <p-tag *ngFor="let item of comercialActivityList" styleClass="m-2" severity="success" [value]="item"></p-tag>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    @if(taxConditions.length > 0){
                                                                        <div class="col-12" *ngIf="profile.country === 'PE'">
                                                                            <div class="user-stats">
                                                                                <strong translate>taxConditions</strong>
                                                                                <span class="text-center">
                                                                                    <p-tag *ngFor="let item of taxConditions" styleClass="m-2" severity="info" [value]="item.language_name"></p-tag>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    
                                                                    @if(profile.country === 'PE'){
                                                                        @if(profile.GoodContributorStatus){
                                                                            <div class="col-12 flex justify-content-center">
                                                                                <div class="contribuyente mx-3">
                                                                                    <b class="inline-block mr-1" style="color: var(--green-500);">BUEN CONTRIBUYENTE:</b> <b class="inline-block mr-1">{{profile.GoodContributorResolution}}</b> - <b class="inline-block ml-1">{{profile.GoodContributorDate}}</b>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        @if(profile.WithholdingAgentStatus){
                                                                            <div class="col-12 flex justify-content-center">
                                                                                <div class="retencion mx-3">
                                                                                    <b class="inline-block mr-1" style="color: var(--blue-500);">AGENTE DE RETENCION:</b> <b class="inline-block mr-1">{{profile.WithholdingAgentResolution}}</b> - <b class="inline-block ml-1">{{profile.WithholdingAgentDate}}</b>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    }
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <div class="social">
                                                                                <a *ngIf="profile.web.length > 0" target="_blank" [href]="profile.web" class="mr-4 ">
                                                                                    <span class="fa fa-globe text-lg"></span>
                                                                                </a>
                                                                                <a *ngIf="profile.linkedin.length > 0" target="_blank" [href]="profile.linkedin" class="mr-4">
                                                                                    <span class="fab fa-linkedin text-lg"></span>
                                                                                </a>
                                                                                <a *ngIf="profile.facebook.length > 0" target="_blank" [href]="profile.facebook" class="">
                                                                                    <span class="fab fa-facebook text-lg"></span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12" *ngIf="profile.brochure.length > 0 || profile.presentationLetter.length > 0">
                                                                        <div class="grid" style="border-top: 1px solid; font-size: 11px;">
                                                                            <div [ngClass]="{'border-set': profile.brochure.length > 0 && profile.presentationLetter.length > 0}"
                                                                                *ngIf="profile.brochure.length > 0"
                                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                                style="     margin-top: 13px;"
                                                                                (click)="files = profile.brochure;opFiles.toggle($event)">
                                                                                <span class="uppercase"
                                                                                    translate>brochure</span>
                                                                                <span class="ml-2 pi pi-download"></span>
                                                                            </div>
                                                                            <div *ngIf="profile.presentationLetter.length > 0"
                                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                                style="    margin-top: 13px;"
                                                                                (click)="files = profile.presentationLetter;opFiles.toggle($event)">
                                                                                <span
                                                                                    class="uppercase">presentation</span><span
                                                                                    class="ml-2 pi pi-download"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </p-scrollPanel>
                    </div>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center"
                            style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                            <div class="col-12 flex align-items-center justify-content-between" *ngIf="tabSidebar_info === 'resumen'">
                                <h2 class="m-0" style="width: 70%;display: inline;" translate>SUMMARY</h2>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-12 p-0">
                    
                       
                                <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
                                    <div class="grid m-0">
                                        <ng-container *ngIf="profile">
                                            <div class="col-12">
                                                <h3 class="m-0" translate>company_summary</h3>
                                            </div>
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="profile.summary.length === 0" translate>no_data_available</p>
                                                <p class="m-0" *ngIf="profile.summary.length > 0">{{profile.summary}}</p>
                                            </div>
                                            <div class="col-12">
                                                <h3 class="m-0" translate>panelMission</h3>
                                            </div>
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="profile.mission.length === 0" translate>no_data_available</p>
                                                <p class="m-0" *ngIf="profile.mission.length > 0">{{profile.mission}}</p>
                                            </div>
                                            <div class="col-12">
                                                <h3 class="m-0" translate>panelVision</h3>
                                            </div>
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="profile.vision.length === 0" translate>no_data_available</p>
                                                <p class="m-0" *ngIf="profile.vision.length > 0">{{profile.vision}}</p>
                                            </div>
                                        </ng-container>
                                        
                                        <div class="col-12">
                                            <h3 class="m-0" translate>values</h3>
                                        </div>
                                        <div class="col-12">
                                            <div id="chartdivprofilev1" style="text-align: center;      display: flex;   justify-content: center; flex-flow: wrap; align-content: center;">
                                                    <ng-container *ngFor="let item of dataGr">
                                                            <p-tag styleClass="mr-2 mb-2" value="{{item}}"></p-tag>
                                                    </ng-container>
                                                </div>
                                        </div>

                                        <div class="col-12">
                                            <h3 class="m-0" translate>business_lines</h3>
                                        </div>
                                        <div class="col-12">
                                            <p-table [value]="categories" [paginator]="true" [rows]="5" [scrollable]="true">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="min-width: 50px; width: 50px;"></th>
                                                        <th style="min-width: 150px;" translate>segment</th>
                                                        <th style="min-width: 150px;" translate>group</th>
                                                        <th style="min-width: 150px;" translate>subgroup</th>
                                                        <th style="min-width: 150px;" translate>category</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-data>
                                                    <tr>
                                                        <td>
                                                            <span class="icon-back" [style.background]="data['color']">
                                                                <i class="{{data['icon']}}" aria-hidden="true"></i>
                                                            </span>
                                                        </td>
                                                        <td>{{lang === 'es' ? data.segmentEs : data.segmentEn}}</td>
                                                        <td>{{lang === 'es' ? data.groupEs : data.groupEs }}</td>
                                                        <td>{{lang === 'es' ? data.subGroupEs : data.subGroupEs }}</td>
                                                        <td>{{lang === 'es' ? data.categoryEs : data.categoryEs }}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>UNSPSC</h3>
                                        </div>
                                        <div class="col-12">
                                            <p-table [value]="categoriesUnspsc" [scrollable]="true" [paginator]="true" [rows]="5">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="min-width: 150px;" translate>segment</th>
                                                        <th style="min-width: 150px;" translate>group</th>
                                                        <th style="min-width: 150px;" translate>subgroup</th>
                                                        <th style="min-width: 150px;" translate>category</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-data>
                                                    <tr>
                                                        <td>{{lang === 'es' ? data.segmentEs : data.segmentEn}}</td>
                                                        <td>{{lang === 'es' ? data.groupEs : data.groupEs }}</td>
                                                        <td>{{lang === 'es' ? data.subGroupEs : data.subGroupEs }}</td>
                                                        <td>{{lang === 'es' ? data.categoryEs : data.categoryEs }}</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>certifications</h3>
                                        </div>
                                        <div class="col-12">
                                                <div class="row service-items">
                                                    <div class="col col-d-12 col-t-12 col-m-12 border-line-h p-0" style="display: flex;">
                                                        <div *ngIf="certificateISO9001"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'ISO_9001' | translate }}" shape="circle" image="assets/certificacion/1. CALIDAD.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateGMP"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}" shape="circle" image="assets/certificacion/2. GMP.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateBCR"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" shape="circle" image="assets/certificacion/3. BRC.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateHACCP"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" shape="circle" image="assets/certificacion/4. HACCP.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateBASC"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" shape="circle" image="assets/certificacion/4. BASC.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateISO14001"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'ISO_14001' | translate }}" shape="circle" image="assets/certificacion/7. AMBIENTE.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateISO18001"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" shape="circle" image="assets/certificacion/6. SEGURIDAD.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>line_products_services</h3>
                                        </div>
                                        <div class="col-12">
                                            <p class="m-0" *ngIf="catalogues.length === 0" translate>
                                                no_data_available</p>
                                                <ng-container *ngIf="catalogues.length > 0">
                                                    <div class="grid-system">
                                                        <div class="item" *ngFor="let items of catalogues">
                                                            <app-view-product [item]="items" [type]="'SUMMARY'"></app-view-product>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="m-0" translate>infrastructure</h3>
                                        </div>
                                        <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                                            <ng-container>
                                                <div class="list-button-float" *ngIf="!listMap">
                                                    <div class="circle-off"></div>
                                                    <button style="height:40px; width:40px;  background: black;" pButton
                                                        pRipple type="button" icon="fas fa-list-ul"
                                                        class="p-button-rounded p-button-secondary "
                                                        (click)="listMap = true"></button>
                                                </div>
                                                <div class="container-list-map animate__animated animate__slideInRight" *ngIf="listMap">
                                                    <ng-container *ngIf="infraestructures.length === 0">
                                                        <div class="grid m-0 w-full h-full justify-content-end">
                                                            <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                                style="width: 50%;">
                                                                <span style="color: white; font-size: 18px; text-align: center;"  translate>not_registered_infrastructures</span>
                                                                <button pButton pRipple type="button" label="{{ 'closeU' | translate }}"
                                                                    class="p-button-rounded p-button-danger mt-5 w-button"
                                                                    (click)="closeListMap()"></button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="infraestructures.length > 0">
                                                        <div class="grid m-0">
                                                            <div class="col-12 text-right tex">
                                                                <div class="circle"></div>
                                                                <button
                                                                    style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                                    pButton pRipple type="button" icon="fas fa-times"
                                                                    class="p-button-rounded p-button-danger"
                                                                    (click)="closeListMap()">
                                                                </button>
                                                            </div>
                                                            <div class="col-12">
                                                                <p-scrollPanel
                                                                    [style]="{width: '100%', height: '339px'}">
                                                                    <ng-container *ngFor="let item of infraestructures">
                                                                        <app-list-properties [type]="'SUMMARY'"
                                                                            [item]="item"
                                                                            (location)="openLocation(item)">
                                                                        </app-list-properties>
                                                                    </ng-container>
                                                                </p-scrollPanel>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <google-map height="465px" width="100%" [zoom]="zoom" [center]="center">
                                                <map-marker #somemarker="mapMarker" *ngFor="let item of infraestructures" [position]="item.position"
                                                    (mapClick)="openInfo(somemarker, item)">
                                                </map-marker>
                                                <map-info-window>
                                                    <ng-container *ngIf="temporalInfraestructure">
                                                        <div style="width: 150px;     flex-direction: column;"
                                                            class="flex align-items-center flex-wrap text-center ">
                                                            <p class="m-0 text-blue-500 text-center">
                                                                <strong class="uppercase">{{temporalInfraestructure.name}}</strong>
                                                            </p>
                                                            <p class="m-0  text-center"> {{temporalInfraestructure.nameType}} - {{temporalInfraestructure.area}}m2</p>
                                                            <p class="m-0 text-center">
                                                                <strong>{{temporalInfraestructure.address}}</strong>
                                                            </p>
                                                        </div>
                                                    </ng-container>
                                                </map-info-window>
                                            </google-map>
                                        </div>
                                        
                                    </div>
                                </p-scrollPanel>
                    </div>
                </div>
            </div>
            <div class="col-2 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center"
                            style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
                            <div class="col-12">
                                <h2 class="m-0"></h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
                            <div class="grid m-0">
                                <div class="mb-4 col-12 flex align-items-center justify-content-center py-0">
                                    <div class="text-center">
                                        <p class="mt-0 font-bold" translate>INFORMATION</p>
                                        <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic"
                                            style="font-size: 100px;">
                                            <span>{{informationScore}}</span>
                                            <div class="slice flex align-items-center justify-content-center">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4 col-12 flex align-items-center justify-content-center py-0">

                                    <div class="text-center">
                                        <p class=" font-bold" translate>REPUTATION</p>
                                        <div class="c100 {{reputationColor}} p{{reputationNum}} small backgroud-graphic"
                                            style="font-size: 100px;">
                                            <span>{{reputationScore}}</span>
                                            <div class="slice flex align-items-center justify-content-center">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4 col-12 flex align-items-center justify-content-center py-0">

                                    <div class="text-center">
                                        <p class=" font-bold" translate>TRUST</p>
                                        <div class="c100 {{trustColor}} p{{trustNum}} small backgroud-graphic"
                                            style="font-size: 100px;">
                                            <span>{{trustScore}}</span>
                                            <div class="slice flex align-items-center justify-content-center">
                                                <div class="bar"></div>
                                                <div class="fill"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-scrollPanel>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-0 text-right" style="margin-top: calc(1em + 10px);">
                <div class="grid">
                    <div class="col-10 p-0 text-left flex align-items-center">
                        <ng-container *ngIf="!loadComplete">
                            <ng-container *ngIf="typeShow != 'USER' && plan ==='PREMIUM' && (idCompany != client_id) && (statusInvite === 'REJECTED' || !statusInvite)">
                                <button pButton pRipple type="button" label="{{'INVITE' | translate}}" [disabled]="statusInvite === 'ACCEPTED' || statusInvite === 'PENDING'" class=" p-button-rounded w-button p-button-secondary" (click)="sendInvitation()" [loading]="loadInvitation"></button>
                                <button pButton pRipple type="button" (click)="op.toggle($event)" icon="fas fa-question" class="p-button-rounded p-button-secondary p-button-outlined ml-2" ></button>
                            </ng-container>
                            <span class="font-bold inline-block ml-3" translate>
                                <ng-container *ngIf="statusInvite === 'ACCEPTED'"> <i class="fas fa-globe mr-2" style="color:var(--bold-text);font-size: 20px;" pTooltip="{{'in_my_network' | translate }}" tooltipposition="bottom"></i> <span translate style="color:var(--green-500)">in_my_network</span></ng-container>
                                <ng-container *ngIf="statusInvite === 'PENDING'">request_sent_info_supplier <span>{{infoInvitation['createdDate'] * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}</span></ng-container>
                                <ng-container *ngIf="statusInvite === 'REJECTED'">request_denied_supplier <span>{{infoInvitation['answerInvitationDate']  * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}</span></ng-container>
                            </span>
                        </ng-container>
                    </div>
                    <div class="col-2 p-0">
                        <button pButton pRipple type="button" label="{{'closeU' | translate}}" [loading]="loadComplete"
                            class=" p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="grid m-0 flex md:hidden">
        <div class="col-12 mt-4" style="height: 48.96px; border-bottom: 1px solid var(--surface-d)">
           <h2 class="m-0" translate>SUMMARY</h2>
           
        </div>
        <div class="col-12 p-0">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 165px)'}">
                <ng-container *ngIf="!loadComplete">
                    <div class="grid m-0">
                        <div class="col-12">
                            <div class="container shadow-5" style="border-radius: 24px;">
                                <div class="card-profile" style="padding-top: 20px;">
                                    <img *ngIf="profile.logo.length > 0" class="w-full px-3"
                                        style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                        [src]="profile.logo" alt="">
                                    <img *ngIf="profile.logo.length === 0" class="w-full px-3"
                                        style="object-fit:contain; height: 125px!important; border-radius: 24px;"
                                        src="assets/images/no_logo.jpeg" alt="">
                                    <div class="card-profile_user-stats relative">
                                        <div class="grid m-0">
                                            <div class="col-12" style="margin-top:10px">
                                                <div class="stats-holder">
                                                    <div class="user-stats">
                                                        <div class="user-infos">
                                                            <span class="infos_name">{{profile.businessName}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="stats-holder flex align-items-center justify-content-center">
                                                        <img class="mr-3" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{profile.country | lowercase}}.png" alt="" srcset="">
                                                        <p-tag [value]="profile.tradeName" class="tag-info"></p-tag>
                                                        <span class="inline-block ml-3" style="height: 24px;"
                                                            *ngIf="profile.homologation === 'YES'">
                                                            <app-animated-check>
                                                            </app-animated-check>
                                                        </span>
                                                    </div>
                                                    <div class="grid mt-2">
                                                        <div class="col-12">
                                                            <div class="user-stats flex align-items-center justify-content-between">
                                                                <span>
                                                                    <p-tag styleClass="ml-2 mr-2"
                                                                        severity="info" [value]="years"></p-tag>
                                                                </span>
                                                                <span>{{profile.codeTributary}}</span>
                                                                <span>
                                                                    <button pButton pRipple type="button" icon="pi pi-paperclip" label="{{profile.files.length}}" 
                                                                    class="p-button-rounded p-button-secondary mr-2 p-button-outlined" (click)="files = profile.files; opFiles.toggle($event)"></button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="user-stats">
                                                                <span>{{profile.direction}} <br>
                                                                    {{countryName}} - {{profile.state}} - {{profile.city}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="user-stats">
                                                                <strong class="lowercase"
                                                                    translate>CONTACT</strong>
                                                                <span>
                                                                    <p class="m-0">
                                                                        <span class="mr-2 inline-block ">
                                                                            <i
                                                                                class="fas fa-mobile-alt mr-2"></i>
                                                                            <span class="">{{profile.prefixPhone}} {{profile.phone}}</span>
                                                                        </span>
                                                                    </p>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="user-stats">
                                                                <strong translate>comercial_activity</strong>
                                                                <span class="text-center">
                                                                    <p-tag *ngFor="let item of comercialActivityList" styleClass="m-2" severity="success" [value]="item"></p-tag>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12" *ngIf="profile.country === 'PE'">
                                                            <div class="user-stats">
                                                                <strong translate>taxConditions</strong>
                                                                <span class="text-center">
                                                                    <p-tag *ngFor="let item of taxConditions" styleClass="m-2" severity="info" [value]="item.language_name"></p-tag>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="user-stats">
                                                                <strong translate>type_company</strong>
                                                                <span>{{typeCompany}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="user-stats">
                                                                <strong translate>commercial_section</strong>
                                                                <span>{{commercialSectionList}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="user-stats">
                                                                <div class="social">
                                                                    <a *ngIf="profile.web.length > 0" target="_blank" [href]="profile.web" class="mr-4 ">
                                                                        <span class="fa fa-globe text-lg"></span>
                                                                    </a>
                                                                    <a *ngIf="profile.linkedin.length > 0" target="_blank" [href]="profile.linkedin" class="mr-4">
                                                                        <span class="fab fa-linkedin text-lg"></span>
                                                                    </a>
                                                                    <a *ngIf="profile.facebook.length > 0" target="_blank" [href]="profile.facebook" class="">
                                                                        <span class="fab fa-facebook text-lg"></span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12" *ngIf="profile.brochure.length > 0 || profile.presentationLetter.length > 0">
                                                            <div class="grid" style="border-top: 1px solid; font-size: 11px;">
                                                                <div [ngClass]="{'border-set': profile.brochure.length > 0 && profile.presentationLetter.length > 0}"
                                                                    *ngIf="profile.brochure.length > 0"
                                                                    class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                    style="     margin-top: 13px;"
                                                                    (click)="files = profile.brochure;opFiles.toggle($event)">
                                                                    <span class="uppercase"
                                                                        translate>brochure</span>
                                                                    <span class="ml-2 pi pi-download"></span>
                                                                </div>
                                                                <div *ngIf="profile.presentationLetter.length > 0"
                                                                    class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                    style="    margin-top: 13px;"
                                                                    (click)="files = profile.presentationLetter;opFiles.toggle($event)">
                                                                    <span
                                                                        class="uppercase">presentation</span><span
                                                                        class="ml-2 pi pi-download"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 pl-0 flex align-items-center justify-content-center py-0">
                            <div class="text-center flex justify-content-center flex-wrap">
                                <p class=" font-bold" translate>INFORMATION</p>
                                <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic"
                                    style="font-size: 75px;">
                                    <span>{{informationScore}}</span>
                                    <div class="slice flex align-items-center justify-content-center">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 px-0 flex align-items-center justify-content-center py-0">

                            <div class="text-center flex justify-content-center flex-wrap">
                                <p class=" font-bold" translate>REPUTATION</p>
                                <div class="c100 {{reputationColor}} p{{reputationNum}} small backgroud-graphic"
                                    style="font-size: 75px;">
                                    <span>{{reputationScore}}</span>
                                    <div class="slice flex align-items-center justify-content-center">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 pr-0 flex align-items-center justify-content-center py-0">

                            <div class="text-center flex justify-content-center flex-wrap">
                                <p class=" font-bold" translate>TRUST</p>
                                <div class="c100 {{trustColor}} p{{trustNum}} small backgroud-graphic"
                                    style="font-size: 75px;">
                                    <span>{{trustScore}}</span>
                                    <div class="slice flex align-items-center justify-content-center">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>company_summary</h3>
                        </div>
                        <div class="col-12">
                            <p class="m-0" *ngIf="profile.summary.length === 0" translate>no_data_available</p>
                            <p class="m-0" *ngIf="profile.summary.length > 0">{{profile.summary}}</p>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>panelMission</h3>
                        </div>
                        <div class="col-12">
                            <p class="m-0" *ngIf="profile.mission.length === 0" translate>no_data_available</p>
                            <p class="m-0" *ngIf="profile.mission.length > 0">{{profile.mission}}</p>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>panelVision</h3>
                        </div>
                        <div class="col-12">
                            <p class="m-0" *ngIf="profile.vision.length === 0" translate>no_data_available</p>
                            <p class="m-0" *ngIf="profile.vision.length > 0">{{profile.vision}}</p>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>values</h3>
                        </div>
                        <div class="col-12">
                            <div id="chartdivprofilev1" style="text-align: center;      display: flex;   justify-content: center; flex-flow: wrap; align-content: center;">
                                <ng-container *ngFor="let item of dataGr">
                                    <p-tag styleClass="mr-2 mb-2" value="{{item}}"></p-tag>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3 class="m-0" translate>business_lines</h3>
                        </div>
                        <div class="col-12">
                            <p-table [value]="categories" [paginator]="true" [rows]="5" [scrollable]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="min-width: 50px; width: 50px;"></th>
                                        <th style="min-width: 150px;" translate>segment</th>
                                        <th style="min-width: 150px;" translate>group</th>
                                        <th style="min-width: 150px;" translate>subgroup</th>
                                        <th style="min-width: 150px;" translate>category</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td>
                                            <span class="icon-back" [style.background]="data['color']">
                                                <i class="{{data['icon']}}" aria-hidden="true"></i>
                                            </span>
                                        </td>
                                        <td>{{lang === 'es' ? data.segmentEs : data.segmentEn}}</td>
                                        <td>{{lang === 'es' ? data.groupEs : data.groupEs }}</td>
                                        <td>{{lang === 'es' ? data.subGroupEs : data.subGroupEs }}</td>
                                        <td>{{lang === 'es' ? data.categoryEs : data.categoryEs }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="col-12">
                                            <h3 class="m-0" translate>UNSPSC</h3>
                        </div>
                        <div class="col-12">
                            <p-table [value]="categoriesUnspsc" [scrollable]="true" [paginator]="true" [rows]="5">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="min-width: 150px;" translate>segment</th>
                                        <th style="min-width: 150px;" translate>group</th>
                                        <th style="min-width: 150px;" translate>subgroup</th>
                                        <th style="min-width: 150px;" translate>category</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data>
                                    <tr>
                                        <td>{{lang === 'es' ? data.segmentEs : data.segmentEn}}</td>
                                        <td>{{lang === 'es' ? data.groupEs : data.groupEs }}</td>
                                        <td>{{lang === 'es' ? data.subGroupEs : data.subGroupEs }}</td>
                                        <td>{{lang === 'es' ? data.categoryEs : data.categoryEs }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="col-12">
                                            <h3 class="m-0" translate>certifications</h3>
                        </div>
                        <div class="col-12">
                                                <div class="row service-items">
                                                    <div class="col col-d-12 col-t-12 col-m-12 border-line-h p-0" style="display: flex;">
                                                        <div *ngIf="certificateISO9001"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'ISO_9001' | translate }}" shape="circle" image="assets/certificacion/1. CALIDAD.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateGMP"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'GOOD_MANUFACTURING_PRACTICES' | translate }}" shape="circle" image="assets/certificacion/2. GMP.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateBCR"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'WORLD_FOOD_SAFETY_STANDARD' | translate }}" shape="circle" image="assets/certificacion/3. BRC.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateHACCP"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'RISK_ANALYSIS_AND_CRITICAL_CONTROL_POINTS' | translate }}" shape="circle" image="assets/certificacion/4. HACCP.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateBASC"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'ANTI_SMUGGLING_BUSINESS_COALITION' | translate }}" shape="circle" image="assets/certificacion/4. BASC.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateISO14001"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'ISO_14001' | translate }}" shape="circle" image="assets/certificacion/7. AMBIENTE.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="certificateISO18001"  style="padding: 5px;">
                                                            <div style="display: flex; align-items: center; margin: 5px 0px; justify-content: center;">
                                                                <p-avatar styleClass="cursor-pointer" tooltipPosition="left" pTooltip="{{ 'OSHA_18001_ISO_45001' | translate }}" shape="circle" image="assets/certificacion/6. SEGURIDAD.svg"></p-avatar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                        </div>
                        <div class="col-12">
                                            <h3 class="m-0" translate>line_products_services</h3>
                        </div>
                        <div class="col-12">
                                            <p class="m-0" *ngIf="catalogues.length === 0" translate>
                                                no_data_available</p>
                                                <ng-container *ngIf="catalogues.length > 0">
                                                    <div class="grid-system">
                                                        <div class="item" *ngFor="let items of catalogues">
                                                            <app-view-product [item]="items" [type]="'SUMMARY'"></app-view-product>
                                                        </div>
                                                    </div>
                                                </ng-container>
                        </div>
                        <div class="col-12">
                                            <h3 class="m-0" translate>infrastructure</h3>
                        </div>
                        <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                                            <ng-container>
                                                <div class="list-button-float" *ngIf="!listMap">
                                                    <div class="circle-off"></div>
                                                    <button style="height:40px; width:40px;  background: black;" pButton
                                                        pRipple type="button" icon="fas fa-list-ul"
                                                        class="p-button-rounded p-button-secondary "
                                                        (click)="listMap = true"></button>
                                                </div>
                                                <div class="container-list-map animate__animated animate__slideInRight" *ngIf="listMap">
                                                    <ng-container *ngIf="infraestructures.length === 0">
                                                        <div class="grid m-0 w-full h-full justify-content-end">
                                                            <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                                style="width: 50%;">
                                                                <span style="color: white; font-size: 18px; text-align: center;"  translate>not_registered_infrastructures</span>
                                                                <button pButton pRipple type="button" label="{{ 'closeU' | translate }}"
                                                                    class="p-button-rounded p-button-danger mt-5 w-button"
                                                                    (click)="closeListMap()"></button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="infraestructures.length > 0">
                                                        <div class="grid m-0">
                                                            <div class="col-12 text-right tex">
                                                                <div class="circle"></div>
                                                                <button
                                                                    style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                                    pButton pRipple type="button" icon="fas fa-times"
                                                                    class="p-button-rounded p-button-danger"
                                                                    (click)="closeListMap()">
                                                                </button>
                                                            </div>
                                                            <div class="col-12">
                                                                <p-scrollPanel
                                                                    [style]="{width: '100%', height: '339px'}">
                                                                    <ng-container *ngFor="let item of infraestructures">
                                                                        <app-list-properties [type]="'SUMMARY'"
                                                                            [item]="item"
                                                                            (location)="openLocation(item)">
                                                                        </app-list-properties>
                                                                    </ng-container>
                                                                </p-scrollPanel>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <google-map height="465px" width="100%" [zoom]="zoom" [center]="center">
                                                <map-marker #somemarker="mapMarker" *ngFor="let item of infraestructures" [position]="item.position"
                                                    (mapClick)="openInfo(somemarker, item)">
                                                </map-marker>
                                                <map-info-window>
                                                    <ng-container *ngIf="temporalInfraestructure">
                                                        <div style="width: 150px;     flex-direction: column;"
                                                            class="flex align-items-center flex-wrap text-center ">
                                                            <p class="m-0 text-blue-500 text-center">
                                                                <strong class="uppercase">{{temporalInfraestructure.name}}</strong>
                                                            </p>
                                                            <p class="m-0  text-center"> {{temporalInfraestructure.nameType}} - {{temporalInfraestructure.area}}m2</p>
                                                            <p class="m-0 text-center">
                                                                <strong>{{temporalInfraestructure.address}}</strong>
                                                            </p>
                                                        </div>
                                                    </ng-container>
                                                </map-info-window>
                                            </google-map>
                        </div>
                        <div class="col-12" *ngIf="!loadComplete">
                                <ng-container *ngIf="typeShow != 'USER' && plan ==='PREMIUM' && (idCompany != client_id) && (statusInvite === 'REJECTED' || !statusInvite)">
                                    <button pButton pRipple type="button" label="{{'INVITE' | translate}}" [disabled]="statusInvite === 'ACCEPTED' || statusInvite === 'PENDING'" class=" p-button-rounded w-button p-button-secondary" (click)="sendInvitation()" [loading]="loadInvitation"></button>
                                    <button pButton pRipple type="button" (click)="op.toggle($event)" icon="fas fa-question" class="p-button-rounded p-button-secondary p-button-outlined ml-2" ></button>
                                </ng-container>
                                <span class="font-bold block mt-2" translate *ngIf="statusInvite">
                                    <ng-container *ngIf="statusInvite === 'ACCEPTED'"> <i class="fas fa-globe mr-2" style="color:var(--bold-text);font-size: 20px;" pTooltip="{{'in_my_network' | translate }}" tooltipposition="bottom"></i> <span class="mr-2" translate style="color:var(--green-500)">in_my_network</span></ng-container>
                                    <ng-container *ngIf="statusInvite === 'PENDING'">request_sent_info_supplier <span>{{infoInvitation['createdDate'] * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}</span></ng-container>
                                    <ng-container *ngIf="statusInvite === 'REJECTED'">request_denied_supplier <span>{{infoInvitation['answerInvitationDate']  * 1000 | date: 'dd/MM/yyyy, h:mm:ss a'}}</span></ng-container>
                                </span>
                        </div>
                    </div>
                </ng-container>
            </p-scrollPanel>
        </div>
        <div class="col-12 flex align-items-center">
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" [loading]="loadComplete" class=" p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
        </div>
    </div>
</p-sidebar>

<p-overlayPanel #opFiles>
    <ng-template pTemplate>
        
            <app-list-files [files]="files">
            </app-list-files>
        
    </ng-template>
</p-overlayPanel>


<p-overlayPanel #op>
    <ng-template pTemplate>
        <div class="grid">
            <div class="col-12">
                <p class='m-0 font-bold'>{{'invite_text_one' | translate }}</p>
                <p class='m-0 font-bold' style="color:var(--pink-500)">{{'invite_text_two' | translate}}</p>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>